import githubLogo from "../../static/images/GithubLogo.png";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import React from "react";
import spongebob from "../../static/images/spongebob.png";
import patrickstar from "../../static/images/patrickstar.png";
import squid from "../../static/images/squid.png";
import sandy from "../../static/images/sandy.png";
import krabs from "../../static/images/krabs.png";
const people = [
  {
    name: "Sponge Bob",
    email: "Spongey#3446",
    avgCompletionRate: 100,
    lastAssignments: [3, 3, 2],
    kudos: 14,
    image: spongebob,
  },
  {
    name: "Patrick Star",
    email: "iamsmart#6129",
    avgCompletionRate: 92,
    lastAssignments: [3, 3, 1],
    kudos: 10,
    image: patrickstar,
  },
  {
    name: "Squidward",
    email: "Squidward#7812",
    avgCompletionRate: 70,
    lastAssignments: [4, 4, 0],
    kudos: 3,
    image: squid,
  },
  {
    name: "Sandy Cheeks",
    email: "Cheeks#6712",
    avgCompletionRate: 45,
    lastAssignments: [3, 4, 2],
    kudos: 7,
    image: sandy,
  },
  {
    name: "Mr. Krabs",
    email: "MrKrabs#7812",
    avgCompletionRate: 80,
    lastAssignments: [4, 3, 0],
    kudos: 10,
    image: krabs,
  },
];

export default function StudentsTable() {
  return (
    <section className="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="table-auto min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Student
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last 3 assignments
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Kudos
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Github Profile</span>
                    </th>
                    <th scope="col" className="w-20 relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {people.map((person) => (
                    <tr key={person.email}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={person.image}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {person.name}
                            </div>
                            <div className="text-sm text-gray-500">
                              @{person.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap font-light">
                        {person.lastAssignments.map((status, index) => {
                          switch (status) {
                            case 0:
                              return (
                                <span
                                  key={index}
                                  className="px-2 mr-1 inline-flex text-xs leading-5 rounded-full bg-gray-200 text-gray-800"
                                >
                                  inactive
                                </span>
                              );
                            case 1:
                              return (
                                <span
                                  key={index}
                                  className="px-2 mr-1 inline-flex text-xs leading-5 rounded-full bg-yellow-100 text-yellow-800"
                                >
                                  in progress
                                </span>
                              );
                            case 2:
                              return (
                                <span
                                  key={index}
                                  className="px-2 mr-1 inline-flex text-xs leading-5 font-semibold underline rounded-full cursor-pointer bg-blue-100 text-blue-800"
                                >
                                  feedback ready
                                </span>
                              );
                            case 3:
                              return (
                                <span
                                  key={index}
                                  className="px-2 mr-1 inline-flex text-xs leading-5 rounded-full bg-green-100 text-green-800"
                                >
                                  done
                                </span>
                              );
                            case 4:
                              return (
                                <span
                                  key={index}
                                  className="px-2 mr-1 inline-flex text-xs leading-5 rounded-full bg-red-100 text-red-800"
                                >
                                  missed
                                </span>
                              );
                          }
                        })}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                        <span
                          className="relative text-gray-800 text-s p-2 font-semibold cursor-pointer"
                          onClick={() => {
                            person.kudos++;
                            console.log(person);
                          }}
                        >
                          {person.kudos}
                          <span className="text-2xl mx-2">👏</span>
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <span className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                          <img
                            className="h-6 w-6"
                            src={githubLogo}
                            alt="Github profile button"
                          />
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <DotsVerticalIcon className="h-6 text-indigo-500 cursor-pointer" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
