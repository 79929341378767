import React, { useEffect } from "react";
import "./../styles/global.scss";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Route, Router, Switch } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import "./../util/analytics";
import { AuthProvider } from "../util/auth";
import { QueryClientProvider } from "../util/db";

function App() {
  useEffect(() => {
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = "d0dd4232-3b12-4828-bf0e-934d8854870c";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      // @ts-ignore
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  });

  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>
            {/*<NavbarCustom*/}
            {/*  bg="white"*/}
            {/*  variant="light"*/}
            {/*  expand="md"*/}
            {/*  logo="https://uploads.divjoy.com/logo.svg"*/}
            {/*/>*/}

            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/pricing" component={PricingPage} />

              <Route exact path="/dashboard" component={DashboardPage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/settings/:section" component={SettingsPage} />

              <Route exact path="/legal/:section" component={LegalPage} />

              <Route exact path="/purchase/:plan" component={PurchasePage} />

              <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              />

              <Route component={NotFoundPage} />
            </Switch>
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
