/* This example requires Tailwind CSS v2.0+ */
import { Switch } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingSwitch({
  enabled,
  onToggle,
}: {
  enabled: boolean;
  onToggle: () => any;
}) {
  return (
    <Switch.Group as="div" className="flex justify-center items-center mb-8">
      <Switch.Label as="span" className="mx-3">
        <span className="text-base font-normal text-gray-800">Monthly</span>
      </Switch.Label>
      <Switch
        checked={enabled}
        onChange={onToggle}
        className={classNames(
          enabled ? "bg-purple" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="mx-3">
        <span className="text-base font-normal text-gray-900">Annual</span>
        <span className="text-base inline-flex items-center mx-2 px-2.5 py-0.5 rounded-md text-sm font-medium bg-yellow text-black">
          2 months free
        </span>
      </Switch.Label>
    </Switch.Group>
  );
}
