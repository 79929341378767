import React, { useState } from "react";
import { useForm } from "react-hook-form";

function CtaContact() {
  const [subscribed, setSubscribed] = useState(false);
  const { handleSubmit, register, errors, getValues } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);

    saveEmail(email);
  };

  const onSubscribed = () => {};

  const saveEmail = (email, range = "") => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "post",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify([[email, range]]),
    };

    fetch(
      "https://v1.nocodeapi.com/techiecub/google_sheets/PloDRJPUmfRIAmsX?tabId=Sheet1",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => onSubscribed(email))
      .catch((error) => console.log("error", error));
  };

  return (
    <section id="join">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 my-20">
        {/* CTA box */}
        <div className="dark relative bg-purple py-10 px-8 md:py-16 md:px-12">
          {/* Background illustration */}
          <BackgroundIllustration />

          <div className="relative max-w-3xl mx-auto text-center grid place-items-center">
            {/* CTA header */}
            <h2 className="text-4xl font-extrabold mb-2 text-white">
              {subscribed ? (
                "Thanks for joining!"
              ) : (
                <div className="relative">We're launching soon!</div>
              )}
            </h2>
            <p className="text-gray-300 text-xl text-white">
              {subscribed ? (
                "You'll hear from us soon. ✌️"
              ) : (
                <>
                  Drop your email below to receive updates or get in touch at{" "}
                  <a
                    href="mailto:hey@techiecub.io"
                    className="underline text-yellow hover:text-yellow-hover"
                  >
                    hey@techiecub.io
                  </a>
                </>
              )}
            </p>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`relative mt-8 flex rounded-md shadow-sm md:w-1/2 ${
                subscribed ? "hidden" : ""
              }`}
            >
              <span className="flex h-3 w-3 absolute -left-1.5 -top-1.5">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow"></span>
              </span>
              <input
                type="email"
                name="email"
                id="email-cta"
                className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-0"
                placeholder="Enter email here"
                ref={register({
                  required: "Please enter an email address",
                })}
              />
              <button
                type="submit"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base text-purple font-medium shadow-sm text-white bg-yellow hover:bg-yellow-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-hover rounded-r-md"
              >
                Join
              </button>
            </form>
            <span className="text-sm text-gray-300 italic">
              No span, we promise
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CtaContact;

const BackgroundIllustration = () => {
  return (
    <div
      className="absolute inset-0 left-auto  pointer-events-none"
      aria-hidden="true"
    >
      <svg
        className="h-full"
        width="400"
        height="232"
        viewBox="0 0 400 232"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <radialGradient
            cx="50%"
            cy="50%"
            fx="50%"
            fy="50%"
            r="39.386%"
            id="box-gr-a"
          >
            <stop stopColor="#1F1C9C" offset="0%" />
            <stop stopColor="#1F1C9C" stopOpacity="0" offset="100%" />
          </radialGradient>
          <radialGradient
            cx="50%"
            cy="50%"
            fx="50%"
            fy="50%"
            r="39.386%"
            id="box-gr-b"
          >
            <stop stopColor="#2320AD" offset="0%" />
            <stop stopColor="#2320AD" stopOpacity="0" offset="100%" />
          </radialGradient>
        </defs>
        <g transform="translate(-85 -369)" fill="none" fillRule="evenodd">
          <circle
            fillOpacity=".16"
            fill="url(#box-gr-a)"
            cx="413"
            cy="688"
            r="240"
          />
          <circle
            fillOpacity=".24"
            fill="url(#box-gr-b)"
            cx="400"
            cy="400"
            r="400"
          />
        </g>
      </svg>
    </div>
  );
};
