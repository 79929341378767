import React from "react";
import logo from "../../static/images/logo-transparent-bg.png";

const Footer = () => {
  return (
    <div className="flex flex-col items-center justify-center align-center pt-4 pb-8 text-gray-600">
      <img src={logo} alt="Footer Logo" className="h-16" />
      <span className="my-3">
        Bootstrapped with{" "}
        <span className="text-red-600">{String.fromCodePoint(0x2764)}</span>
      </span>
    </div>
  );
};

export default Footer;
