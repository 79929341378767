import React, { useState } from "react";
import PricingTier from "./PricingTier";
import PricingSwitch from "./PricingSwitch";

export enum PricingMode {
  Monthly = "monthly",
  Yearly = "yearly",
}

function PricingTables() {
  const [billAnnually, setBillAnnually] = useState(false);

  return (
    <section className="relative border-t border-transparent md:mt-12">
      {/* End background gradient (dark version only) */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          {/*<div className="max-w-3xl mx-auto text-center pb-12">*/}
          {/*  <h2 className="text-3xl font-bold mb-4">*/}
          {/*    Start building for free, then add a plan to go live*/}
          {/*  </h2>*/}
          {/*  <p className="text-xl text-gray-600">*/}
          {/*    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui*/}
          {/*    officia deserunt mollit laborum — semper quis lectus nulla.*/}
          {/*  </p>*/}
          {/*</div>*/}

          <PricingSwitch
            enabled={billAnnually}
            onToggle={() => setBillAnnually(!billAnnually)}
          />

          {/* Pricing tables */}
          <div className="max-w-xs mx-auto grid sm:grid-cols-2 lg:grid-cols-4 items-start sm:max-w-none md:max-w-2xl lg:max-w-none">
            {/* Pricing table 1 */}
            <PricingTier
              name="Class"
              billAnnually={billAnnually}
              perMonthPrice={49}
              students={25}
            />
            <PricingTier
              highlighted={true}
              name="Bootcamp"
              billAnnually={billAnnually}
              perMonthPrice={249}
              students={150}
            />
            <PricingTier
              name="Scale"
              billAnnually={billAnnually}
              perMonthPrice={599}
              students={500}
            />
            {/* Pricing features */}
            <div className="sm:order-first">
              <div className="text-right hidden sm:block">
                <svg
                  className="inline-flex -mt-3 mr-5 mb-3"
                  width="86"
                  height="25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current text-yellow"
                    d="M80.959 10.448l-5.502-8.292 1.666-1.105 8.596 12.953-15.534.62-.08-1.999 9.944-.397-7.182-3.672C45.251-3.737 21.787 1.633 2.216 24.726L.69 23.435C20.836-.338 45.252-5.926 73.73 6.752l7.23 3.696z"
                  />
                </svg>
              </div>
              <div className="text-lg font-bold mb-4 mt-4 sm:mt-0 text-gray-600">
                All plans include:
              </div>
              <ul className="text-gray-600 -mb-2 grow">
                <li className="flex items-center mb-2">
                  <svg
                    className="w-5 h-5 fill-current text-yellow mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Unlimited teachers</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg
                    className="w-5 h-5 fill-current text-yellow mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Unlimited courses</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg
                    className="w-5 h-5 fill-current text-yellow mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Badge creation</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg
                    className="w-5 h-5 fill-current text-yellow mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Assignments automation</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg
                    className="w-5 h-5 fill-current text-yellow mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Human support</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg
                    className="w-5 h-5 fill-current text-yellow mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Cake & Cookies</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingTables;
