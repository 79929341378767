import React from "react";

interface FeatureSectionProps {
  handwritingTitle: string;
  title: string;
  description: string;
  image: string;
  imageFirst?: boolean;
}

function FeatureSection({
  handwritingTitle,
  title,
  description,
  image,
  imageFirst = false,
}: FeatureSectionProps) {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 z-50">
        <div className="py-12 md:py-20">
          {/* Items */}

          <div className="grid gap-20" data-aos-id-features-home>
            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
                data-aos-anchor="[data-aos-id-features-home]"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="font-architects-daughter text-xl text-purple mb-2 font-light italic">
                    {handwritingTitle}
                  </div>
                  <h3 className="text-3xl font-extrabold mb-3 text-gray-800">
                    {title}
                  </h3>
                  <div className="text-xl text-gray-600 mb-4 whitespace-pre-line space-y-4">
                    {description}
                  </div>
                  {/*<ul className="flex flex-wrap text-lg text-gray-400 -mx-2 -my-1">*/}
                  {/*  <li className="flex items-center mx-2 my-1">*/}
                  {/*    <svg*/}
                  {/*      className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"*/}
                  {/*      viewBox="0 0 12 12"*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*    >*/}
                  {/*      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />*/}
                  {/*    </svg>*/}
                  {/*    <span>Premium statistics</span>*/}
                  {/*  </li>*/}
                  {/*  <li className="flex items-center mx-2 my-1">*/}
                  {/*    <svg*/}
                  {/*      className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"*/}
                  {/*      viewBox="0 0 12 12"*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*    >*/}
                  {/*      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />*/}
                  {/*    </svg>*/}
                  {/*    <span>Unlimited jobs</span>*/}
                  {/*  </li>*/}
                  {/*  <li className="flex items-center mx-2 my-1">*/}
                  {/*    <svg*/}
                  {/*      className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"*/}
                  {/*      viewBox="0 0 12 12"*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*    >*/}
                  {/*      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />*/}
                  {/*    </svg>*/}
                  {/*    <span>Free goods</span>*/}
                  {/*  </li>*/}
                  {/*  <li className="flex items-center mx-2 my-1">*/}
                  {/*    <svg*/}
                  {/*      className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"*/}
                  {/*      viewBox="0 0 12 12"*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*    >*/}
                  {/*      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />*/}
                  {/*    </svg>*/}
                  {/*    <span>No code required</span>*/}
                  {/*  </li>*/}
                  {/*  <li className="flex items-center mx-2 my-1">*/}
                  {/*    <svg*/}
                  {/*      className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"*/}
                  {/*      viewBox="0 0 12 12"*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*    >*/}
                  {/*      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />*/}
                  {/*    </svg>*/}
                  {/*    <span>Always up to date</span>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                </div>
              </div>
              {/* Image */}
              <div
                className={`max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 md:mb-0 ${
                  imageFirst ? "order-first" : ""
                }`}
                data-aos="fade-up"
                data-aos-anchor="[data-aos-id-features-home]"
              >
                <div className="relative">
                  <img
                    className="md:max-w-full"
                    src={image}
                    width="540"
                    height="520"
                    alt="Features illustration"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
