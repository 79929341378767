import React from "react";
import Meta from "./../components/Meta";
import Header from "../components/landing-page/Header";
import Hero from "../components/landing-page/Hero";
import FeatureSection from "../components/landing-page/FeatureSection";
import assignmentsImage from "../static/images/assignments2.png";
import gamificationImage from "../static/images/gamification2.png";
import newclassImage from "../static/images/newclass2.png";
import remindersImage from "../static/images/reminders2.png";
import CtaContact from "../components/landing-page/CtaContact";
import Footer from "../components/landing-page/Footer";
import StudentsTable from "../components/landing-page/StudentsTable";
import Pricing from "../components/landing-page/Pricing";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <div className="flex flex-col min-h-screen overflow-hidden">
        <Header />
        <main className="flex-grow">
          <Hero />

          <FeatureSection
            handwritingTitle="Keep an eye on assignments"
            title="Effortless students progress tracking"
            description={
              <>
                <p>Who finished? Who started? Who's slacking?</p>
                <p>
                  Instructors and students receive instant and daily updates
                  about assignments
                </p>
              </>
            }
            image={remindersImage}
          />
          <FeatureSection
            imageFirst={true}
            handwritingTitle="From repository template to feedback"
            title="Automagically distribute assignments"
            description={
              <>
                <p>
                  We know how tricky Git can be for beginners and how much time
                  is spent by teachers intervening.
                </p>
                <p>
                  TechieCub distributes the assignment from your Github
                  repository to every student, gives access to instructors, and
                  creates the Pull Requests for feedback. Students just need to
                  clone & go.
                </p>
              </>
            }
            image={assignmentsImage}
          />
          <FeatureSection
            // handwritingTitle="Students engagement drops over-time?"
            image={gamificationImage}
            handwritingTitle="Kudos to questions & progress!"
            title="Keep students engaged with gamification"
            description={
              <>
                <p>
                  Lower the chances of engagement dropping over time. It's not a
                  secret anymore that gamification positively impacts students
                  happiness.
                </p>
                <p>
                  That means they're more responsible, care more, do more and
                  learn more!
                </p>
              </>
            }
          />
          <FeatureSection
            handwritingTitle="New students? No problem!"
            title="Automate student onboarding"
            description="TechieCub makes it easy for you to onboard students, create channels, sync their Github accounts, and centralize all of it."
            image={newclassImage}
            imageFirst={true}
          />

          <StudentsTable />

          <Pricing />

          <CtaContact />
          <Footer />
        </main>
      </div>
    </>
  );
}

export default IndexPage;
