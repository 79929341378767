import React from "react";
import { Disclosure } from "@headlessui/react";
import logo from "../../static/images/logo-vertical-color-bg.png";

export default function Header() {
  // const [scrolled, setScrolled] = useState<boolean>(false);
  //
  // useEffect(() => {
  //   const scrollHandler = () => {
  //     window.scrollY > 30 ? setScrolled(true) : setScrolled(false);
  //   };
  //
  //   window.addEventListener("scroll", scrollHandler);
  //
  //   return () => window.removeEventListener("scroll", scrollHandler);
  // }, []);

  return (
    <Disclosure
      as="nav"
      className="transition duration-250 ease-in-out fixed w-full z-50 bg-yellow"
      // className={`transition duration-250 ease-in-out fixed w-full z-50 ${
      //   scrolled ? "bg-white shadow" : "bg-transparent"
      // }`}
    >
      {({ open }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  {/*<Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">*/}
                  {/*  <span className="sr-only">Open main menu</span>*/}
                  {/*  {open ? (*/}
                  {/*    <XIcon className="block h-6 w-6" aria-hidden="true" />*/}
                  {/*  ) : (*/}
                  {/*    <MenuIcon className="block h-6 w-6" aria-hidden="true" />*/}
                  {/*  )}*/}
                  {/*</Disclosure.Button>*/}
                </div>
                <a className="flex-shrink-0 flex items-center" href="#hero">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={logo}
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src={logo}
                    alt="Workflow"
                  />
                </a>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {/*<a*/}
                  {/*  href="#"*/}
                  {/*  className="border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"*/}
                  {/*>*/}
                  {/*  Dashboard*/}
                  {/*</a>*/}
                  {/*<a*/}
                  {/*  href="#"*/}
                  {/*  className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"*/}
                  {/*>*/}
                  {/*  Team*/}
                  {/*</a>*/}
                  {/*<a*/}
                  {/*  href="#"*/}
                  {/*  className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"*/}
                  {/*>*/}
                  {/*  Projects*/}
                  {/*</a>*/}
                  {/*<a*/}
                  {/*  href="#"*/}
                  {/*  className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"*/}
                  {/*>*/}
                  {/*  Calendar*/}
                  {/*</a>*/}
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {/*<button*/}
                  {/*  type="button"*/}
                  {/*  className={`transition duration-250 ease-in-out transform hover:-rotate-3 relative inline-flex items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-purple shadow-sm hover:bg-purple-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-hover ${*/}
                  {/*    scrolled ? "opacity-100" : "opacity-0"*/}
                  {/*  } `}*/}
                  {/*>*/}
                  {/*  <PlusSmIcon*/}
                  {/*    className="-ml-1 mr-2 h-5 w-5"*/}
                  {/*    aria-hidden="true"*/}
                  {/*  />*/}
                  {/*  <span>New Job</span>*/}
                  {/*</button>*/}
                </div>
              </div>
            </div>
          </div>

          {/*<Disclosure.Panel className="md:hidden bg-white">*/}
          {/*  <div className="pt-2 pb-3 space-y-1">*/}
          {/*    /!* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" *!/*/}
          {/*    <a*/}
          {/*      href="#"*/}
          {/*      className="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"*/}
          {/*    >*/}
          {/*      Dashboard*/}
          {/*    </a>*/}
          {/*    <a*/}
          {/*      href="#"*/}
          {/*      className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"*/}
          {/*    >*/}
          {/*      Team*/}
          {/*    </a>*/}
          {/*    <a*/}
          {/*      href="#"*/}
          {/*      className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"*/}
          {/*    >*/}
          {/*      Projects*/}
          {/*    </a>*/}
          {/*    <a*/}
          {/*      href="#"*/}
          {/*      className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"*/}
          {/*    >*/}
          {/*      Calendar*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</Disclosure.Panel>*/}
        </>
      )}
    </Disclosure>
  );
}
