import React from "react";
import logoSmall from "../../static/images/logo-color-small.png";
import { ChevronDownIcon } from "@heroicons/react/solid";

function Hero() {
  return (
    <section
      className="relative bg-yellow md:h-screen flex flex-col justify-center items-center pb-12 md:pb-0 px-4 pt-8"
      id="hero"
    >
      {/* Illustration behind hero content */}
      <div className="text-center md:max-w-6xl pt-32 md:pt-0">
        <h1
          className="text-purple text-5xl font-extrabold leading-tighter tracking-tighter mb-8"
          data-aos="zoom-y-out"
        >
          Gamify and Track your Bootcamp
          <br />
          right from your Slack / Discord
        </h1>
        <div className="max-w-3xl mx-auto">
          <p
            className="text-xl text-gray-800 mb-12"
            data-aos="zoom-y-out"
            data-aos-delay="150"
          >
            A <strong>Lightweight LMS</strong> for Coding classes. Easily{" "}
            <strong>automate</strong> and <strong>track</strong> assignments
            while keeping students engaged with <strong>gamification</strong>{" "}
            right from your Discord/Slack.
          </p>
          <div
            className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
            data-aos="zoom-y-out"
            data-aos-delay="300"
          >
            <div>
              <a
                href="#join"
                className="cursor-pointer transition duration-150 ease-in-out transform hover:-rotate-2 inline-flex items-center px-6 py-3 mb-7 border border-transparent rounded-md text-base font-medium shadow-sm text-white bg-purple hover:bg-purple-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-hover"
              >
                Join the Beta
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center text-purple md:mt-16">
        <img src={logoSmall} className="w-20" />
        <ChevronDownIcon className="animate-bounce md:mt-4 h-10" />
      </div>
    </section>
  );
  // return (
  //   <section className="relative bg-yellow md:h-screen" id="hero">
  //     {/* Illustration behind hero content */}
  //     <div className="max-w-6xl mx-auto px-4">
  //       {/* Hero content */}
  //       <div className="pt-32 pb-12 md:pt-40 md:pb-20">
  //         {/* Section header */}
  //         <div className="text-center">
  //           <h1
  //             className="text-purple text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-8"
  //             data-aos="zoom-y-out"
  //           >
  //             Automate Your Coding Bootcamp & Focus on Teaching
  //           </h1>
  //           <div className="max-w-3xl mx-auto">
  //             <p
  //               className="text-xl text-gray-800 mb-12"
  //               data-aos="zoom-y-out"
  //               data-aos-delay="150"
  //             >
  //               TechieCub is a <strong>Lightweight LMS</strong> for Coding
  //               classes. Easily <strong>track</strong>,{" "}
  //               <strong>distribute</strong> and keep students engaged with{" "}
  //               <strong>gamification</strong> right from your Discord/Slack.
  //             </p>
  //             <div
  //               className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
  //               data-aos="zoom-y-out"
  //               data-aos-delay="300"
  //             >
  //               <div>
  //                 <a
  //                   href="#join"
  //                   className="cursor-pointer transition duration-150 ease-in-out transform hover:-rotate-2 inline-flex items-center px-6 py-3 mb-7 border border-transparent rounded-md text-base font-medium shadow-sm text-white bg-purple hover:bg-purple-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-hover"
  //                 >
  //                   Join the Beta
  //                 </a>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="flex flex-col items-center text-purple md:mt-16">
  //           <img src={logoSmall} className="w-20" />
  //           <ChevronDownIcon className="animate-bounce md:mt-4 h-10" />
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // );
}

export default Hero;
