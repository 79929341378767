import React from "react";

const PricingTier = ({
  name,
  billAnnually,
  perMonthPrice,
  students,
  highlighted,
}: {
  name: string;
  billAnnually: boolean;
  perMonthPrice: number;
  students: number;
  highlighted?: boolean;
}) => {
  return (
    <div
      className={`flex flex-col h-fit p-6 shadow-lg ${
        highlighted ? "bg-yellow" : "mt-0 md:mt-6"
      }`}
      data-aos="fade-down"
    >
      <div className="grow mb-4 pb-4">
        <div className="flex justify-between items-center">
          <div className="text-xl font-normal text-gray-600">{name}</div>
          {/*{billAnnually ? (*/}
          {/*  <span*/}
          {/*    className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${*/}
          {/*      highlighted*/}
          {/*        ? "bg-white text-gray-800"*/}
          {/*        : "text-gray-800 bg-yellow"*/}
          {/*    }`}*/}
          {/*  >*/}
          {/*    -20%*/}
          {/*  </span>*/}
          {/*) : (*/}
          {/*  ""*/}
          {/*)}*/}
        </div>
        <div className="flex items-center mb-1 font-normal text-gray-600">
          {"< "}
          <span className="text-gray-800 font-semibold mx-1 text-2xl">
            {students}
          </span>
          monthly students
        </div>
        <div className="inline-flex items-baseline">
          <span className="text-2xl text-gray-600">$</span>
          <span className="text-4xl font-extrabold mx-1">
            {billAnnually ? perMonthPrice * 10 : perMonthPrice}
          </span>
          <span className="font-medium text-gray-600 ">
            /{billAnnually ? "year" : "month"}
          </span>
        </div>
        <div className="flex flex-row font-light text-sm text-gray-600 italic">
          (~$
          {((perMonthPrice * (billAnnually ? 10 : 12)) / students / 12).toFixed(
            1
          )}{" "}
          /per student)
        </div>
      </div>
      <div
        className={`flex justify-center mt-0 ${
          highlighted ? "md:mt-20" : "md:mt-6"
        }`}
      >
        <div>
          <a
            href="#join"
            className="cursor-pointer transition duration-150 ease-in-out transform hover:-rotate-2 inline-flex items-center px-6 py-3 border border-transparent rounded-md text-base font-medium shadow-sm text-white bg-purple hover:bg-purple-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-hover"
          >
            Get started
          </a>
        </div>
      </div>
    </div>
  );
};

export default PricingTier;
